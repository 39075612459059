import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../../components/layout';
import { CommonContext } from '../../context';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlogCaption from '../../components/BlogCaptionOnScreenSize';

export const query = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: ["featured", "archived"] } } }
        }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        slug
        title
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
      }
    }
    allFeaturedWpPost: allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { eq: "featured", ne: "archived" } } }
        }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        slug
        title
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

function BlogLandingPage({ data, location }) {
  const blogList = [...data.allWpPost.nodes];
  const featuredBlogList = [...data.allFeaturedWpPost.nodes];

  const renderBlogTile = (item) => (
    <Link to={`/blog/${item.slug}/`} key={item.slug}>
      <div className="mb-6 transition duration-500 ease-in-out md:w-full rounded-2xl hover:shadow bg-neutral overflow-clip">
        <GatsbyImage
          className="rounded-t-2xl"
          image={item.featuredImage.node.gatsbyImage}
          alt={item.featuredImage.node.altText}
        />
        <BlogCaption title={item.title} date={item.date} />
      </div>
    </Link>
  );

  const renderFirstBlogTile = (item) => (
    <Link to={`/blog/${item.slug}/`} key={item.slug}>
      <div className="relative transition duration-500 ease-in-out md:w-full hover:shadow-2xl">
        <div
          className="hidden w-full mx-auto rounded-2xl md:block overflow-clip"
          style={{
            height: '580px',
          }}
        >
          <GatsbyImage
            className="w-full"
            image={item.featuredImage.node.gatsbyImage}
            alt={item.featuredImage.node.altText}
          />
        </div>
        <div
          className="block w-full mx-auto rounded-2xl md:hidden"
          style={{
            height: '400px',
          }}
        >
          <GatsbyImage
            className="w-full"
            image={item.featuredImage.node.gatsbyImage}
            alt={item.featuredImage.node.altText}
          />
        </div>
        <div className="absolute bottom-0 w-full h-full bg-gradient-to-tr from-neutral-9 to-transparent rounded-2xl"></div>
        <div className="absolute bottom-0 w-full p-4 bg-transparent text-neutral md:pl-12 md:p-0 ">
          <div className="mb-2 text-sm uppercase font-semiBold md:text-base">
            {item.categories.nodes.map((cat) => cat.name).join(' | ')}
          </div>
          <div className="mb-2 text-3xl font-extraBold md:font-semiBold md:text-6xl md:w-10/12">
            {item.title}
          </div>
          <div className="mb-6 font-semiBold md:mb-11">{item.date}</div>
        </div>
      </div>
    </Link>
  );

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title="Blog"
        description={
          'Discover latest news and articles from Greentech Apps Foundation'
        }
        location={location}
      >
        <div className="flex justify-center w-full bg-primary-0">
          <div className="w-full max-w-6xl px-6 xl:px-0">
            <div className="py-8 text-xl font-regular md:text-3xl">
              Discover latest news and articles
            </div>
            <div className={'mb-8'}>
              {renderFirstBlogTile(featuredBlogList[0])}
            </div>
            <div className={'md:grid md:grid-cols-2 md:gap-8 mb-8'}>
              {featuredBlogList.slice(1).map((item) => renderBlogTile(item))}
            </div>
            <div
              className={'md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 mb-8'}
            >
              {blogList.map((item) => renderBlogTile(item))}
            </div>
            <div className="py-12 text-center font-semiBold text-primary md:py-20">
              <Link to="/blog/archives/">View Archive</Link>
            </div>
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

BlogLandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default BlogLandingPage;
